<template>
  <v-row class="ma-5">
    <overlay :loading="loading" />
    <v-col class="pl-1" cols="12">
      <v-btn class="pl-0" text @click="$router.go(-1)">
        <v-icon class="pr-1">mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
    </v-col>
    <v-col cols="12" class="text-h6 black--text pb-0">
      Cadastro de novo profissional
    </v-col>
    <v-col cols="12" class="subtitle-2 grey--text pt-0">
      Insira as informações solicitadas abaixo para novo cadastro
    </v-col>
    <v-col cols="12" class="text-h6"> Dados de identificação </v-col>
    <v-col cols="12">
      <v-form
        ref="createProfessionalForm"
        v-model="formValidate"
        lazy-validation
      >
        <v-row>
          <v-col class="pb-0" cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" class="pb-0">
                Nome <span class="red--text"> * </span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.name"
                  @keypress="blockNumber($event)"
                  clearable
                  outlined
                  placeholder="Nome completo"
                  counter="100"
                  required
                  :rules="[(v) => !!v || 'Nome é obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0" cols="12" sm="12" md="3">
            <v-dialog
              ref="birthDateDialog"
              v-model="birthDateDialog"
              :return-value.sync="formData.birth_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-row>
                  <v-col cols="12" class="pr-0 pb-0">
                    Data de nascimento
                    <span class="red--text">*</span>
                  </v-col>
                  <v-col cols="12" class="">
                    <v-text-field
                      v-model="formData.birth_date"
                      placeholder="00/00/0000"
                      clearable
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="[
                        (v) => !!v || 'Data de nascimento é obrigatório',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
              <v-date-picker 
                v-model="formData.birth_date" 
                scrollable
                :max="date"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="birthDateDialog = false">
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.birthDateDialog.save(formData.birth_date)"
                >
                  Selecionar
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col class="pb-0" cols="12" sm="12" md="3">
            <v-row>
              <v-col cols="12" class="pb-0">
                Sexo <span class="red--text"> * </span>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="sexos"
                  v-model="formData.sex"
                  clearable
                  outlined
                  placeholder="Selecione"
                  required
                  :rules="[(v) => !!v || 'Sexo é obrigatório']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0" cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" class="pb-0">
                E-mail <span class="red--text"> * </span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.email"
                  clearable
                  outlined
                  placeholder="example@gmail.com"
                  required
                  :rules="[(v) => !!v || 'E-mail é obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0" cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" class="pb-0">
                Telefone <span class="red--text"> * </span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.phone"
                  clearable
                  outlined
                  placeholder="(00) 0 0000-0000"
                  required
                  v-mask="'(##) # ####-####'"
                  :rules="[(v) => !!v || 'Telefone é obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0" cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" class="pb-0">
                CPF <span class="red--text"> * </span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.cpf"
                  clearable
                  outlined
                  placeholder="000.000.000-00"
                  required
                  v-mask="'###.###.###-##'"
                  :rules="[(v) => !!v || 'CPF é obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pb-0" cols="12" sm="12" md="6">
            <v-row>
              <v-col cols="12" class="pb-0">
                RG <span class="red--text"> * </span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="formData.rg"
                  clearable
                  outlined
                  placeholder="0.000.000"
                  v-mask="'#.###.###'"
                  required
                  :rules="[(v) => !!v || 'RG é obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              @click="$route.params.id ? onUpdate() : onCreate()"
              :disabled="!formValidate"
              :loading="loading"
              class="primary"
            >
              {{ $route.params.id ? "Salvar" : "Cadastrar" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import Overlay from "../../../../components/Overlay/Overlay.vue";
export default {
  components: {
    Overlay,
  },
  data: () => ({
    data: "",
    formValidate: true,
    birthDateDialog: false,
    formData: {
      name: null,
      cpf: null,
      rg: null,
      birthDate: null,
      sex: null,
      email: null,
      role_id: 10,
      password: 123456,
    },
    sexos: [
      {
        text: "Feminino",
        value: "0",
      },
      {
        text: "Masculino",
        value: "1",
      },
    ],
    loading: false,
    e: "",
  }),
  mounted() {
    this.saveCurrentDate();
    if (this.$route.params.id) {
      if (!this.$route.params.data) return this.showProfessional();

      this.$route.params.data.sex =
        this.$route.params.data.sex == "male" ? "1" : "0";
      this.formData = this.$route.params.data;
    }
  },
  methods: {
    saveCurrentDate() {
      var date = new Date();
      var day = String(date.getDate()).padStart(2, "0");
      var mounth = String(date.getMonth() + 1).padStart(2, "0");
      var year = date.getFullYear();
      this.date = year + "-" + mounth + "-" + day;
      // console.log(this.date);
    },
    blockNumber(e){
      if(e.keyCode > 47 && e.keyCode < 58) e.preventDefault();
    },
    async showProfessional () {
      try {
        this.loading = true;

        const response = await this.$axios('users/' + this.$route.params.id);

        response.data.sex = response.data.sex == "male" ? "1" : "0"

        if (response.data) this.formData = response.data;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log('Show professional error: ', error);
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar carregar o profissional",
        });
      }
    },
    async onCreate() {
      this.loading = true;

      if (!this.$refs.createProfessionalForm.validate()) {
        this.loading = false;
        return;
      }

      this.formData.sex = Number(this.formData.sex);

      try {
        await this.$axios
          .post("users", {
            user: this.formData,
          })
          .catch((res) => {
            if (!res.response.data) return;

            const errors = res.response.data;
            const errorMessages = [];

            for (const i in errors) {
              errorMessages.push(`"${i[0].toUpperCase() + i.substr(1)}"`);
            }

            if (res.response.status === 422) {
              this.loading = false;
              this.$swal({
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "MIS",
                text:
                  `${errors.length > 1 ? "Campos " : "Campo"} em uso: ` +
                  errorMessages.join(", "),
              });

              throw new Error("Error 422");
            }
          });

        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Profissional criado com sucesso.",
        });

        this.loading = false;
        this.$router.push({ name: "AdminProfessionals" });
      } catch (error) {
        const msg = error.response.error
        this.loading = false;
        console.log("Create professional error: ", error);
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar criar o profissional", msg,
        });
      }
    },
    async onUpdate() {
      try {
        this.loading = true;

        if (!this.$refs.createProfessionalForm.validate()) {
          this.loading = false;
          return;
        }

        this.formData.sex = Number(this.formData.sex);

        delete this.formData.password;

        await this.$axios.put("users/" + this.$route.params.id, this.formData);

        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "success",
          title: "MIS",
          text: "Profissional atualizado com sucesso.",
        });

        this.loading = false;
        this.$router.push({ name: "AdminProfessionals" });
      } catch (error) {
        this.loading = false;
        console.log("Update professional error: ", error);
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: "MIS",
          text: "Ocorreu um error ao tentar atualizar o profissional",
        });
      }
    },
  },
};
</script>

<style></style>
